<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
       <b-img
              :src="appLogoImage"
              alt="logo"
            />
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">
          OneToko!
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5 ">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-4"
        v-if="!isMultiAcountStatus && !getGoogleMultipleAccount"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to OneToko! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <!--  -->
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2 ">
            <span>If You New User? </span>
            <b-link :to="{ name: 'signup' }" class="small">
              <span>Create New Account</span>
            </b-link>
          </p>

          <hr />
          <!-- loginWithGoogle -->
          <!-- @click="router.push('/google/login/access-token')" -->
          <div class="google-btn" @click="loginWithGoogle()">
            <div class="google-icon-wrapper">
              <img
                class="google-icon"
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              />
            </div>
            <p class="btn-text"><b>Sign in with google</b></p>
          </div>

          <!-- divider -->

          <!-- social buttons -->
        </b-col>
      </b-col>

      <!-- /Login-->

<!-- google multiple login start -->

    <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-3"
        v-if="getGoogleMultipleAccount"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-25">
            Choose An Goolge Account 
          </b-card-title>
          <b-card-text class="mb-2 text-secondary">
            to continue to signin with app
          </b-card-text>
<!-- get_access_token, get_email, get_shopname -->
          <b-card no-body class="mb-2">
            <b-list-group flush>
              <b-list-group-item
                class="cursor-pointer"
                v-for="(googleLoginitem, index) in getGoogleMultipleAccount"
                :key="index"
                @click="
                  multipleGoolgeAccountLogin(googleLoginitem.email, googleLoginitem.company_shopify_name, )
                "
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      :src="googleLoginitem.profile_pic"
                      variant="light-success"
                      size="42"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0 text-break">
                      {{ googleLoginitem.name }}
                    </h5>
                    <b-card-text class="font-small-3 mb-0 text-break">
                      {{ googleLoginitem.email }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <hr />
          <b-card no-body class="mb-2">
            <b-list-group>
              <b-list-group-item
                class="cursor-pointer"
                @click="otherGoogleLoginAccount()"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar size="42" variant="light-primary">
                      <feather-icon size="24" icon="UserIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="font-weight-600 mb-0 text-primary text-break">
                      Login in to a different account
                    </h6>
                  </b-media-body>
                </b-media>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-col>

<!-- google multiple login end -->



      <!-- Multi Login-->

      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-3"
        v-if="isMultiAcountStatus"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-0 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-25">
            Choose An Account 
          </b-card-title>
          <b-card-text class="mb-2 text-secondary">
            to continue to signin with app
          </b-card-text>

          <b-card no-body class="mb-2">
            <b-list-group flush>
              <b-list-group-item
                class="cursor-pointer"
                v-for="(loginItem, index) in userAccounts"
                :key="index"
                @click="
                  multipleAccountLogin(loginItem.JWT_Token, loginItem.username)
                "
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      :src="loginItem.profile_pic"
                      variant="light-success"
                      size="42"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h5 class="font-weight-bolder mb-0 text-break">
                      {{ loginItem.name }}
                    </h5>
                    <b-card-text class="font-small-3 mb-0 text-break">
                      {{ loginItem.email }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <hr />
          <b-card no-body class="mb-2">
            <b-list-group>
              <b-list-group-item
                class="cursor-pointer"
                @click="otherLoginAccount()"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar size="42" variant="light-primary">
                      <feather-icon size="24" icon="UserIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="font-weight-600 mb-0 text-primary text-break">
                      Login in to a different account
                    </h6>
                  </b-media-body>
                </b-media>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
;
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { avatarText } from '@core/utils/filter';
import axiosIns from '@/libs/axios';
import { mapGetters } from 'vuex'
import { $themeConfig } from '@themeConfig'
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    avatarText,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      status: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      email,
      isMultiAcountStatus: '',
      userAccounts: '',

       
    
    };
  },
 
 
  computed: {
...mapGetters({
      getGoogleMultipleAccount: 'app-customer/getGoogleMultipleAccount',
    }),


    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  created() {
    if (!this.$cookies.get('token')) this.$router.push('/login');
  },

  setup(){
     const {appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },

  methods: {
    otherLoginAccount() {
      this.isMultiAcountStatus = false;
    },

    otherGoogleLoginAccount(){
    this.isMultiAcountStatus = false; 
    this.$store.commit('app-customer/SET_GOOGLE_MULTIPLE_ACCOUNT',null)

    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          const payload = {
            email: this.form.email,
            password: this.form.password,
          };

          store
            .dispatch('app-customer/login', payload)
            .then((response) => {
              console.log('response_login_1', response);
              this.$cookies.set('token', response.JWT_Token);
              this.$cookies.set('company_id', response.company_id);
              this.userAccounts = response.user_accounts;
              if (response.user_accounts != null) {
                console.log('multiple account here');
                this.isMultiAcountStatus = true;
              } else {
                if (response.profile_flag) {
                  this.$router.push({ name: 'update-profile' });
                } else {
                  this.$router.push('/');
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Invalid email or password',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    multipleAccountLogin(get_jwttoken, get_username) {
      const payload = {
        email: get_username,
        password: this.form.password,
      };

      store
        .dispatch('app-customer/login', payload)
        .then((response) => {
          console.log(response);
          this.$cookies.set('token', get_jwttoken);
          this.$router.push('/');
          console.log(response);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.result.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          });
        });
    },

    loginWithGoogle() {
      let state = new Date().getTime();
      // console.log('state data', state);
      let googleUrl = `https://accounts.google.com/o/oauth2/auth?response_type=token&client_id=330804759778-589658l0sfni2vsgh6ivmj71ctgctid5.apps.googleusercontent.com&redirect_uri=https://b.m91.link/signin/google/callback/&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&state=${state}`;
      // console.log('google url', googleUrl);
      location.href = googleUrl;
    },


   

       multipleGoolgeAccountLogin(get_email, get_shopname) {
        const getAccesstoken = this.$cookies.get('token');
      
        const payload ={
        company_shopify_name: get_shopname,
        access_token:getAccesstoken,
        email: get_email,
        };

    
 
       axiosIns.post('/multiple/login/validator/', payload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
      .then((response) => {
          console.log(response);
          this.$cookies.set('token', response.data.result.data.JWT_Token);
          this.$router.push('/');
          console.log(response);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something Wrong!',
              icon: 'EditIcon',
              variant: 'danger',
            },
          });
        });
    

    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.google-btn {
  width: 100%;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.google-btn .btn-text {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 0px;
  padding: 12px 0 0 64px;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669f2;
}
</style>
